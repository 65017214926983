<!--
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-13 16:20:48
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-15 17:39:39
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\pages\communityManagement\index.vue
 * @Description: 
-->
<template>
	<div class="user">
		<div class="md-layout" v-loading="loading">

			<div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
				<md-card class="md-card-plain">
					<md-card-header class="card-header" md-alignment="center">
						<!-- <h4 class="title">小区列表展示</h4> -->
						<!-- <p class="category"> -->
						<div class="text-r"><md-button class="md-success" @click="addUser">
								<i class="el-icon-plus mr-5"></i>添加小区</md-button></div>
						<!-- </p> -->
					</md-card-header>
					<md-card-content class="card-content">
						<el-table :data="tableData">
							<el-table-column type="index" align="center" label="编号" width="100">
							</el-table-column>
							<el-table-column prop="name" label="小区名称" min-width="120">
							</el-table-column>
							<el-table-column prop="shortName" label="小区简称" min-width="120">
							</el-table-column>
							<el-table-column prop="level" label="楼幢类型" min-width="120">
								<template slot-scope="scope">
									{{ buildLevels[scope.row.level] || '-' }}
								</template>
							</el-table-column>
							<!-- mobile -->
							<el-table-column prop="nums" label="楼幢数量" min-width="120">
							</el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="240">
								<template slot-scope="scope">
									<el-button type="text" @click="editUser(scope.row)">
										编辑
									</el-button>
									<el-popconfirm @confirm="delUser(scope.row)" confirm-button-text='确认'
										cancel-button-text='取消' icon="el-icon-info" icon-color="red"
										:title="`确认永久删除 ${scope.row.name} 吗？`">
										<el-button slot="reference" class="del-user" type="text">删除</el-button>
									</el-popconfirm>
								</template>
							</el-table-column>
						</el-table>
						<el-pagination class="page" layout="total, prev, pager, next" :current-page="page.pageNum"
							@current-change="updatePagination" :page-size="page.pageSize" :total="page.total">
						</el-pagination>
						<!-- <pagination ref="pagination" :total="page.total" @nextPage="updatePagination" /> -->
						<!-- <el-pagination class="page" layout="total, prev, pager, next" :current-page="1"
							@current-change="updatePagination" :page-size="12" :total="1000">
						</el-pagination> -->
					</md-card-content>
				</md-card>
			</div>
		</div>
		<!-- 添加编辑用户 -->
		<el-dialog :title="dialogFormTitle" :visible.sync="dialogVisible" width="30%" :before-close="handleCloseForm">
			<el-form ref="dialogForm" :rules="formRules" :label-position="labelPosition" label-width="80px"
				:model="userForm">
				<el-form-item label="小区名称" prop="name">
					<el-input v-model="userForm.name" placeholder="请输入小区名称"></el-input>
				</el-form-item>
				<el-form-item label="小区简称" prop="shortName">
					<el-input v-model="userForm.shortName" placeholder="请输入小区简称"></el-input>
				</el-form-item>
				<el-form-item label="类型" prop="type">
					<el-select v-model="userForm.type" placeholder="请选择类型">
						<el-option label="普通住房" :value="1"></el-option>
						<el-option label="写字楼" :value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="楼幢类型" prop="level">
					<el-select v-model="userForm.level" placeholder="请选择楼幢类型" class="auth-select">
						<el-option v-for="(name, index) in buildLevels" :key="name" :label="name" :value="index">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="楼幢数量" prop="nums">
					<el-input v-model="userForm.nums" type="number" placeholder="请输入楼幢数量"></el-input>
					<!-- <el-form-item label="">
						<div>高级设置：</div>
						<el-row v-for="(item, index) in buildInfosArr" class="mb-15">
							<el-col :span="10"><el-input v-model="item.buildInfos" placeholder="提示：1-9表示1到9幢"></el-input>
							</el-col>
							<el-col :span="10">
								<el-select v-model="item.buildLevel" placeholder="请选择楼幢类型" class="auth-select ml-10">
									<el-option v-for="(name, index) in buildLevels" :key="name" :label="name"
										:value="index">
									</el-option>
								</el-select>
							</el-col>
							<el-col :span="4" class="text-c">
								<i v-if="index === 0" class="el-icon-circle-plus-outline icon-add"
									@click="addBuildInfos"></i>
								<i v-else class="el-icon-circle-close icon-add" @click="delBuildInfos(index)"></i>
							</el-col>
						</el-row>
					</el-form-item> -->
				</el-form-item>
				<el-form-item label="排序号">
					<el-input placeholder="请输入排序号(默认0)" type="number" v-model="userForm.sort"></el-input>
				</el-form-item>
				<el-form-item label="小区介绍">
					<el-input type="textarea" :autosize="{ minRows: 5, maxRows: 8 }" placeholder="请输入内容"
						v-model="userForm.remark">
					</el-input>
				</el-form-item>
				<!-- <el-table-column prop="hremark" label="排序号" min-width="120">
								<template slot-scope="scope">
									{{ scope.row.hremark || '-' }}
								</template>
							</el-table-column>
							<el-table-column prop="hremark" label="户备注" min-width="120">
								<template slot-scope="scope">
									{{ scope.row.hremark || '-' }}
								</template>
							</el-table-column> -->
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleCloseForm">取 消</el-button>
				<el-button v-loading="btnLoading" type="success" @click="subUser">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
// import { SimpleTable, OrderedTable } from "@/components";
import * as API from '@/api/communityManagement.js'
// import Pagination from '@/components/pagination/index.vue'
import { formatDate } from '@/utils/assist.js'
export default {
	components: {
		// Pagination
	},
	data () {
		return {
			// 0.独户 1.房间号 2.单元-房间号 了，幢单元-房间号 4 幢-房间号5.混合
			roleInfo: [],
			buildLevels: ['独户', '房间号', '单元-房间号', '幢-单元-房间号', '幢-房间号', '混合'],
			buildInfosArr: [
				{
					buildInfos: '',
					buildLevel: ''
				}
			],
			formRules: {
				name: [
					{
						required: true,
						message: '请输入小区名称',
						trigger: 'blur'
					},
					{
						required: true,
						message: '请输入3到30位字符',
						min: 3,
						max: 30,
						trigger: 'blur'
					}
				],
				shortName: [
					{
						required: true,
						message: '请输入小区别名',
						trigger: 'blur'
					},
					{
						required: true,
						message: '请输入1到30位字符',
						min: 1,
						max: 30,
						trigger: 'blur'
					}
				],
				type: [
					{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}
				],
				level: [
					{
						required: true,
						message: '请选择楼幢类型',
						trigger: 'change'
					},
				],
				nums: [
					{
						required: true,
						message: '请输入楼幢数量',
						trigger: 'blur'
					},
					// {
					// 	required: true,
					// 	message: '请输入1到20位字符',
					// 	min: 1,
					// 	max: 20,
					// 	trigger: 'blur'
					// }
				],
			},
			labelPosition: 'rigin',
			userForm: {
				name: '',
				shortName: '',
				level: '',
				nums: '',
				// buildInfos: [],
				// buildLevels: [],
				remark: '',
				sort: 0,
				type: ''
			},
			dialogVisible: false,
			roleDialogVisible: false,
			dialogFormTitle: '添加小区',
			tableHeaderColor: '',
			tableData: [],
			paginatedUsers: [],
			loading: false,
			btnLoading: false,
			page: {
				total: 0,
				pageNum: 1,
				pageSize: 10
			},
			roleList: [],
			currentUserId: '',
		}
	},
	created () {
		this.getCommunity()
		// this.getRoleList()
	},
	methods: {
		/**
		 * 获取用户列表
		 */
		async getCommunity () {
			this.loading = true
			try {
				// const { pageSize, pageNum } = this.page
				const data = await API.getCommunity()
				if (data.code === 200 && data.data) {
					this.tableData = data.data || []
					this.page.total = data.data.total || this.tableData.length
				}
				this.loading = false
			} catch (error) {
				this.loading = false
				console.error(error)
			}
		},
		addBuildInfos () {
			this.buildInfosArr.push({
				buildInfos: '',
				buildLevel: ''
			})
		},
		delBuildInfos (index) {
			this.buildInfosArr.splice(index, 1)
		},
		/**
		 * 删除用户账号
		 */
		async delUser ({ id, username }) {
			try {
				const data = await API.delUser({ id })

				if (data.code === 200) {
					this.$notify.success({
						message: `已成功删除 ${username} 账号！`,
						title: '提示'
					})
					this.page.pageNum = 1
					this.getCommunity()
				}
			} catch (error) {
				console.error('delUser: ', error)
			}
		},
		async addCommunity () {
			try {
				this.btnLoading = true
				const data = await API.addCommunity({
					...this.userForm,
					buildInfos: this.buildInfosArr.map(v => v.buildInfos),
					buildLevels: this.buildInfosArr.map(v => v.buildLevel),
				})
				if (data.code === 200) {
					this.handleCloseForm()
					this.page.pageNum = 1
					this.getCommunity()
					this.$notify.success({
						title: '提示',
						message: '添加成功'
					})
				}
				this.btnLoading = false
			} catch (error) {
				this.btnLoading = false
				console.error(error)
			}
		},
		async updateCommunity () {
			try {
				this.btnLoading = true
				const data = await API.updateCommunity({
					...this.userForm,
					id: this.currentUserId,
				})
				if (data.code === 200) {
					this.handleCloseForm()
					this.page.pageNum = 1
					this.getCommunity()
					this.$notify.success({
						title: '提示',
						message: '用户修改成功'
					})
				}
				this.btnLoading = false
			} catch (error) {
				this.btnLoading = false
				console.error(error)
			}
		},
		subUser () {
			this.$refs.dialogForm.validate(validator => {
				if (!validator) return
				if (this.dialogFormTitle === '添加小区') {
					this.addCommunity()
				} else {
					this.updateCommunity()
				}
			})
		},
		editUser (row) {
			this.dialogFormTitle = '编辑小区'
			this.currentUserId = row.id
			Object.keys(this.userForm).forEach(key => {
				if (row[key] != undefined) {
					this.userForm[key] = row[key]
				}
			})
			this.dialogVisible = true
		},
		addUser () {
			this.dialogFormTitle = '添加小区'
			this.dialogVisible = true
		},
		/**
		 * 分页翻动
		 */
		updatePagination (page, pageSize, sort, sortOrder) {
			console.log('pagination has updated', page, pageSize, sort, sortOrder);
			this.page.pageNum = page
			this.getCommunity()
		},
		formatDate (dateStr) {
			if (!dateStr) return '-'
			return formatDate(new Date(dateStr).getTime(), 'YY-MM-DD hh:mm')
		},
		handleCloseForm () {
			this.currentUserId = ''
			this.$refs.dialogForm.resetFields()
			// this.userForm.note = ''
			// this.userForm.password = ''
			this.dialogVisible = false
		}
	},
}
</script>

<style lang="scss" scoped>
.card-content {
	background-color: #fff;
}

.auth-select {
	width: 100%;
}

.page {
	margin-top: 20px;
	text-align: right;
}

.del-user {
	padding-left: 12px;
}

.card-header {
	// display: flex;
	// align-items: center;
	// text-align: right;
	background-color: #eee;
}

.icon-add {
	cursor: pointer;
	font-size: 18px;

	&:hover {
		opacity: .7;
	}
}
</style>